import { FC, useLayoutEffect, useRef, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import gsap from 'gsap';

import ThemeItem from '../ThemeItem/ThemeItem';


import { IStore } from 'store/types/store';
import { toggleThemes } from 'store/actionCreators/main';


import { IThemesFC } from './Themes.interfaces';


const Themes: FC<IThemesFC> = ({ type }) => {
    const themeDiv = useRef<HTMLDivElement>(null);


    const dispatch = useDispatch();
    const { isThemesOpen } = useSelector((store: IStore) => store.main);
    const { items, isLoaded } = useSelector((store: IStore) => store.news);
    const [sortedNews, setSortedNews] = useState<any[]>([]);

    const sortNewsItem = (a: any, b: any) => {
        return b.data.amount - a.data.amount;
    }

    useLayoutEffect(() => {

        animateExpansion(isThemesOpen!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isThemesOpen]);

    useEffect(() => {
        const news = Object.entries(items)
            .map((item: any) => {
                const name = item[0];
                const newsContent = item[1];
                return {
                    id: name,
                    data: newsContent,
                };
            })

        .sort(sortNewsItem).filter(el => el.data.amount > 0);

        setSortedNews(news);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);



    const animateExpansion = (isOpen: boolean): void => {
        const themesTitleHeight = document.querySelector('.themes__title')!.clientHeight;
        const themesListHeight = document.querySelector('.themes__list')!.clientHeight;

        const height = window.innerWidth > 660 ? (isOpen ? `${themesTitleHeight + themesListHeight + 150}px` : 0) : (isOpen ? window.innerHeight - 44 : 0);
        gsap.set(themeDiv.current, {
            opacity: isOpen ? 1 : 0,
            delay: isOpen ? 0 : 0.7,
        });
        gsap.to(themeDiv.current, {
            height: height,
            duration: 0.8,
            ease: 'power2.inOut',
        });
    };

    const setIsThemesOpen = () => {
        dispatch(
            toggleThemes()
        )
    }



    return (
        <div className={`${type} expand expand--${type}`} ref={themeDiv}>
            <h2 className='themes__title'>Themes</h2>
            <div className='themes__list'>
                {sortedNews.map(newsItem => <ThemeItem key={newsItem.id + '_theme'} setIsThemesOpen={setIsThemesOpen} cat={newsItem.id} name={newsItem.data.name} />)}
            </div>
        </div>
    );
};

export default memo(Themes);

