import { FC, memo, useEffect } from 'react';


const About: FC = () => {

    useEffect(() => {

        const areNewsHidden = document.body.classList.contains('is-news-hidden');
        !areNewsHidden && document.body.classList.add('is-news-hidden');

        return () => {
            document.body.classList.remove('is-news-hidden');
        }

    }, [])

    return (
        <div className="about">
                <h1 className="title">About</h1>
                <p className='content'>
                    <span>Earthrise Newswire</span><br/> is an environmental news aggregator that provides unbiased perspective on our changing landscapes.<br /><br />
                    Our planet is finally getting the news coverage it deserves. The challenge: We’ve never been more inundated by the news. To make it easier to stay informed and engaged in what’s happening in the world, the <a href="https://earthrise.media/" target="_blank" rel="noreferrer">Earthrise</a> team built a tool to organize and make sense of environmental news.<br /><br />
                    Using artificial intelligence, machine learning, and satellite technology, Earthrise Newswire assesses all the environmental news on the English web, identifies what each story is about, and corroborates the locations of events.<br /><br />
                    <span>Relevant News</span><br/>
                    Earthrise Newswire categorizes stories by location and topic, so you can quickly learn what’s happening in Bali or Baltimore and go deeper by following the story links. Insights and trends show how coverage is shifting across key topics, from waste management to climate justice.<br/><br/>
                    <span>Unique Perspective</span><br/>
                    If you’re a reporter looking to visually illustrate the timeline or magnitude of climate-related issues, <a href="mailto:dan@earthrise.media">contact us</a> about satellite imagery to accompany your story. Our striking, high-res satellite imagery allows audiences to compare landscapes in the same location going back 30 years or sometimes even 30 minutes.
                </p>
        </div>
    )
}

export default memo(About);
