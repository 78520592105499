import * as NEWS_ACTIONS from 'store/actions/news';

import { ITheme } from 'components/Themes/Themes.interfaces';

export const addNews = (formattedData: any, theme: ITheme) => ({
    type: NEWS_ACTIONS.ADD_NEWS,
    payload: {
        cat: theme.id,
        name: theme.name,
        newsItems: formattedData,
        limit: 10,
        percent: 100,
        amount: formattedData.length,
        isActive: true,
    },
})

export const toggleActiveNews = (cat: string) => ({
    type: NEWS_ACTIONS.TOGGLE_NEWS_ACTIVE,
    payload: {
        cat: cat,
    },
});

export const setAllActive = () => ({
    type: NEWS_ACTIONS.SET_ALL_NEWS_ACTIVE,
    payload: {
        cat: '',
    },
});

export const setAllNotActive = () => ({
    type: NEWS_ACTIONS.SET_ALL_NEWS_NOT_ACTIVE,
    payload: {
        cat: '',
    },
});

export const resetLimit = () => ({
    type: NEWS_ACTIONS.RESET_LIMIT,
    payload: {
        cat: '',
    },
});


export const setNoLimit = (cat: string) => ({
    type: NEWS_ACTIONS.SET_NO_LIMIT,
    payload: {
        cat: cat,
    },
});

export const increaseLimit = (cat: string) => ({
    type: NEWS_ACTIONS.INCREASE_LIMIT,
    payload: {
        cat: cat,
    },
});

export const setNewsLoaded = () => ({
    type: NEWS_ACTIONS.SET_LOADED,
    payload: {
        cat: ''
    }
})

export const setNewsPercent = (cat: string, percent: number) => ({
    type: NEWS_ACTIONS.SET_NEWS_PERCENT,
    payload: {
        cat: cat,
        percent: percent
    },
})

export const triggerLatLon = (latlon: [number, number]) => ({
    type: NEWS_ACTIONS.SET_CURRENT_LATLON,
    payload: {
        value: latlon
    }
})