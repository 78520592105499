import * as THREE from 'three';
import { gsap } from 'gsap';

// calcPosFromLatLonRad
interface ICalcPos {
    lon: number;
    lat: number;
    radius: number;
}

export const calcPosFromLatLonRad = ({ lon, lat, radius }: ICalcPos): THREE.Vector3 => {

    const spherical = new THREE.Spherical(
        radius + 0.2,
        THREE.MathUtils.degToRad(90 - lon),
        THREE.MathUtils.degToRad(lat),
    );

    const vector = new THREE.Vector3();
    vector.setFromSpherical(spherical);

    return vector;
}

export const calcPosFromLatLonRad2 = ({ lon, lat, radius }: ICalcPos): { vector3: THREE.Vector3,  quaternion: THREE.Quaternion } => {

    // const phi   = (90 - lat) * (Math.PI / 180);
    const phi   = (lat) * (Math.PI / 180);
    const theta = (lon + 180) * (Math.PI / 180);
    const theta1 = (270 - lon) * (Math.PI / 180);

    const x = -(radius * Math.cos(phi) * Math.cos(theta));
    const z = (radius * Math.cos(phi) * Math.sin(theta));
    const y = (radius * Math.sin(phi));

    const vector = new THREE.Vector3();
    const euler = new THREE.Euler(phi, theta1, 0, 'XYZ');
    const quaternion = new THREE.Quaternion().setFromEuler(euler);
    vector.setX(x);
    vector.setY(y);
    vector.setZ(z);

    return {vector3: vector, quaternion: quaternion};
}




// clearThree
export const clearThree = (obj: any): void => {

    if (!obj.children) { return; }
    while (obj.children.length > 0) {
        clearThree(obj.children[0]);
        obj.remove(obj.children[0]);
    }

    if (obj.geometry) { obj.geometry.dispose(); }

    if (obj.material) {
        Object.keys(obj.material).forEach(prop => {
            if (!obj.material[prop]) {
                return;
            }
            if (obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function') {
                obj.material[prop].dispose();
            }
        });
        obj.material.dispose();
    }
}



// animateGlobeTo
interface IAnimateGlobeTo {
    lat: number;
    lon: number;
    isGlobeMoving: boolean;
    rotationMod: number;
    globe: THREE.Group;
    hotspotsGroup: THREE.Group;
    [key: string]: any;
}


export const animateGlobeTo = ({ lat, lon, isGlobeMoving, rotationMod, globe,
    isTooltip, setTooltip, tooltipData, setTooltipData, tooltipDiv, tooltipChildren, hotspotsGroup, zoomIn  }: IAnimateGlobeTo): void => {


    if (globe && globe.rotation) {

        const currentQuaternion = new THREE.Quaternion();
        currentQuaternion.copy(globe.quaternion);
        const targetCoords = calcPosFromLatLonRad2({ lat, lon, radius: 10.2 });
        const animatedQuaternion = new THREE.Quaternion();
        const animationProgress = {
            current: 0,
        };

        gsap.to(animationProgress, {
            current: 1,
            onUpdate: () => {
                animatedQuaternion.slerpQuaternions(currentQuaternion, targetCoords.quaternion, animationProgress.current);
                globe.quaternion.copy(animatedQuaternion);
            },
            duration: .4,
            onComplete: () => {
                const filteredHotspot = hotspotsGroup.children.filter(mesh => mesh.userData.vx === lat && mesh.userData.vy === lon);
                const canvasCoords = document.querySelector('#globe-wrapper')!.getBoundingClientRect();
                setSpotActive({
                    hMesh: filteredHotspot[0] as THREE.Mesh,
                    x: canvasCoords.left + canvasCoords.width / 2,
                    y: canvasCoords.top + canvasCoords.height / 2,
                    isTooltip: isTooltip,
                    setTooltip: setTooltip,
                    tooltipData: tooltipData,
                    setTooltipData: setTooltipData,
                    tooltipDiv: tooltipDiv,
                    tooltipChildren: tooltipChildren,
                });
            },
        });
    }

};




// setSpotActive
interface ISetSpotActive {
    hMesh: THREE.Mesh,
    x: number,
    y: number,
    [key: string]: any;
}






export const setSpotActive = (
        {
            hMesh,
            x,
            y,
            isGlobeMoving,
            isSpotActive,
            tooltipDiv,
            tooltipChildren,
            setTooltip,
            setTooltipData,
        }: ISetSpotActive): void => {
        if (!hMesh) { return; }
        const hMaterial = hMesh.material as THREE.MeshStandardMaterial;
        hMaterial.color.setHex(0xffffff);
        let tooltipClass = y < 200 ? 'bottom-align' : null;
        let xPos = x + (308 / 2) > window.outerWidth ? x + (308 / 2) - window.outerWidth : 0;



        const data = {
            id: hMesh.userData.id,
            title: hMesh.userData.title,
            source_url: hMesh.userData.source_url,
            location: hMesh.userData.location,
            x: x,
            y: y,
            vx: hMesh.userData.vx,
            vy: hMesh.userData.vy,
            vz: hMesh.userData.vz,
        };

        setTooltip(true);
        setTooltipData(data);
        isGlobeMoving = false;
        isSpotActive = true;

        gsap.fromTo(tooltipDiv.current, {
            opacity: 0,
        }, {
            opacity: 1,
            duration: .2,
        });

        gsap.fromTo(tooltipChildren('.js-curve'), {
            clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)',
        }, {
            clipPath: 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)',
            duration: .3,
        });

        gsap.fromTo(tooltipChildren('.js-text-wrap'), {
            opacity: 0,
        }, {
            opacity: 1,
            duration: .4,
            delay: .2,
        });

        // shift tooltip block & dot when it crossing through window boundries
        gsap.set(tooltipChildren('.js-text-wrap'), {
            left: -xPos,
        });
        gsap.set(tooltipChildren('.js-dot'), {
            left: `calc(50% + ${xPos}px)`,
        });

        if (tooltipClass) { tooltipDiv.current?.classList.add(tooltipClass); }
};


interface IColors {r: number, g: number, b: number};
export const updateCategory = (bottomGradient: HTMLElement, colorObjectStringValue: string, colorObj: IColors, colorObjectValue: IColors, category: string, loadHotspots: any, animateGlobeCenter: any, isInit: boolean) => {
    // tslint:disable-next-line: no-unused-expression
    bottomGradient && gsap.to(bottomGradient, {
        background: `radial-gradient(circle, rgba(${colorObjectStringValue},1) 0%, rgba(223,223,223,0) 100%)`,
        duration: 1.5,
    });

    // tslint:disable-next-line: no-unused-expression
    colorObj && gsap.to(colorObj, {
        r: colorObjectValue.r,
        g: colorObjectValue.g,
        b: colorObjectValue.b,
        duration: 1.5,
    });


    loadHotspots(category);

    if (!isInit) {
        animateGlobeCenter();
    }
};

export const toScreenPosition = (obj: THREE.Object3D, camera: THREE.Camera, canvasWidth: number, canvasHeight: number): THREE.Vector2 =>
{
    const vector = new THREE.Vector3();

    const widthHalf = 0.5 * canvasWidth;
    const heightHalf = 0.5 * canvasHeight;

    obj.updateMatrixWorld();
    vector.setFromMatrixPosition(obj.matrixWorld);
    vector.project(camera);

    vector.x = ( vector.x * widthHalf ) + widthHalf;
    vector.y = - ( vector.y * heightHalf ) + heightHalf;

    const vec2 = new THREE.Vector2(vector.x, vector.y);

    return vec2;

};