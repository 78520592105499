import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentTheme } from 'store/actionCreators/main';

interface IThemeItem {
    name: string;
    cat: string;
    setIsThemesOpen: (arg: boolean) => void;
}


const ThemeItem: FC<IThemeItem> = ({ name, cat, setIsThemesOpen }) => {
    const dispatch = useDispatch();

    const onClick = () => {
        const elNewsWrapper = document.querySelector(`[data-category="${cat}"]`) as HTMLElement;
        if (!!elNewsWrapper) {
            setIsThemesOpen(false);
            window.innerWidth > 1024 ? document.querySelector('.js-main')!.scrollTo(0, elNewsWrapper!.offsetTop) : dispatch(setCurrentTheme(cat));
        }
    };



    return (
        <button className='themes__button' onClick={onClick}>
            {name}
        </button>
    );
};

export default ThemeItem;
