import { FC } from 'react';
import { compose } from 'recompose';
import { Routes, Route, Navigate } from 'react-router-dom';
import usePageTracking from './utils/Tracking';
import ReactGA from 'react-ga';


// route
import Home from 'pages/Home';
import About from 'pages/About';
import Sources from 'pages/Sources';
import NotFound from 'pages/NotFound';

// layout
import Layout from 'components/Layout/Layout';

// components
import Globe from 'components/Globe/Globe';


// hoc
import { withNews } from 'hoc/withNews';
import { withThemes } from 'hoc/withThemes';

const enhance = compose(withNews, withThemes);





const App: FC = () => {
  ReactGA.initialize('G-VG0TRF4VZK');
  usePageTracking();

  return (
          <Layout>
            <>
              <Routes>
                    <Route path='*' element={<Navigate to='/404' />} />
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/sources' element={<Sources />} />
                    <Route path='/404' element={<NotFound />} />
              </Routes>
              <Globe />
            </>
          </Layout>
  );
};



export default enhance(App);
