import { FC, memo } from 'react';

import { INewsInfo } from './NewsInfo.interfaces';


const NewsInfo: FC<INewsInfo> = ({ amount, leadingNumber, percent }) => (
    <div className='news__info'>

        <div className='news__info--item'>
            {amount}
            <span>Total stories</span>
        </div>

        <div className='news__info--item'>
            {leadingNumber}
            <span>Leading theme</span>
        </div>

        <div className='news__info--item'>
            {percent}%
            <span>Of all news</span>
        </div>

    </div>
);


export default memo(NewsInfo);