import * as THEME_ACTIONS from 'store/actions/theme';
import { IThemesAction } from 'store/types/theme';
import { ITheme, IThemes } from 'components/Themes/Themes.interfaces';

const initialState: IThemes = {
    themes: [],
    currentTheme: ''
};



export const themeReducer = (state = initialState, action: IThemesAction) => {

    switch (action.type) {



        case THEME_ACTIONS.ADD_THEMES:
            return { ...state, themes: action.payload.themesItems as ITheme[] }



        case THEME_ACTIONS.TOGGLE_THEMES_ACTIVE:
            return {...state, themes: state.themes.map(theme => {

                if (theme.id !== action.payload.cat) return theme;

                return {
                    ...theme,
                    isActive: !theme.isActive,
                };

            })};



        case THEME_ACTIONS.SET_THEMES_DISACTIVE:
            return {...state, themes: state.themes.map(theme => {
                return {
                    ...theme,
                    isActive: false,
                };
            })};



        default:
            return state;
    }

}