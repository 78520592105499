
import { gsap } from 'gsap';

interface IAnimate {
    [key: string]: any;
}

export const animateOut = ({ onComplete }: IAnimate) => {
    const loader = document.querySelector('.js-loader') as HTMLElement;
    const globe = document.querySelector('.js-loader-globe') as HTMLElement;
    const title = document.querySelector('.js-loader-title') as HTMLElement;
    const footer = document.querySelector('.js-loader-footer') as HTMLElement;
    const lineTop = document.querySelector('.js-line-top') as HTMLElement;
    const lineBottom = document.querySelector('.js-line-bottom') as HTMLElement;

    gsap.to(globe, {
        scale: .3,
        opacity: 0,
        delay: 1.2,
        ease: 'power2.out',
    });

    gsap.to(title, {
        clipPath: `inset(50% 0 50% 0)`,
        delay: 1,
        ease: 'power3.out',
    });

    gsap.to(lineTop, {
        y: title.clientHeight / 2 + 1,
        delay: 1,
        ease: 'power3.out',
    });

    gsap.to(lineBottom, {
        y: -title.clientHeight / 2 - 1,
        delay: 1,
        ease: 'power3.out',
    });

    gsap.to(footer, {
        opacity: 0,
        delay: 1.2,
    });

    gsap.to(loader, {
        opacity: 0,
        duration: 1,
        delay: 1.6,
        ease: 'power3.out',
        onComplete
    });
}



export const animateIn = () => {
    const loader = document.querySelector('.js-loader') as HTMLElement;
    const globe = loader.querySelector('.js-loader-globe') as HTMLElement;
    const title = loader.querySelector('.js-loader-title') as HTMLElement;
    const footer = loader.querySelector('.js-loader-footer') as HTMLElement;
    const lineTop = loader.querySelector('.js-line-top') as HTMLElement;
    const lineBottom = loader.querySelector('.js-line-bottom') as HTMLElement;
    const delay = .6;

    gsap.fromTo(globe, {
        scale: .3,
        opacity: 0,
    }, {
        scale: 1,
        opacity: 1,
        duration: 1.4,
        ease: 'power3.out',
        delay: delay + .4,
    });

    gsap.fromTo(title, {
        clipPath: `inset(50% 0 50% 0)`,
    }, {
        clipPath: `inset(0% 0 0% 0)`,
        ease: 'power3.out',
        duration: 1.4,
        delay: delay,
    });

    gsap.fromTo(lineTop, {
        y: title.clientHeight / 2 + 1,

    }, {
        y: 0,
        duration: 1.4,
        ease: 'power3.out',
        delay: delay,
    });

    gsap.fromTo(lineBottom, {
        y: -title.clientHeight / 2 - 2,
    }, {
        y: 0,
        duration: 1.4,
        ease: 'power3.out',
        delay: delay,
    });

    gsap.fromTo(footer, {
        opacity: 0,
    }, {
        opacity: 1,
        delay: delay + 1.2,
    });

}