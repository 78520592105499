import { useState, useEffect } from 'react';
import { formatDate, IDateObject } from 'utils/all';




export const useFormattedDate = () => {

    const [date, setDate] = useState<IDateObject>(formatDate(new Date()));


    useEffect(() => {
        const dateObj = new Date();
        let interval = 0;

        if (dateObj.getSeconds() !== 0) {
            interval = window.setTimeout(() => {
                setDate(formatDate(new Date()));
                window.setInterval(() => setDate(formatDate(new Date())), 60000);
            }, 60000 - (dateObj.getSeconds() * 1000))
        }  else {
            interval = window.setInterval(() => setDate(formatDate(new Date())), 60000);
        }

        return () => { window.clearInterval(interval); }
    }, [])

    return [date]


}