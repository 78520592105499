import { FC, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from 'store/types/store';

// scaffold
import Loader from 'components/Loader/Loader';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';
import Themes from 'components/Themes/Themes';




const Layout: FC = ({ children }) => {

    const { isMenuOpen, isThemesOpen, isLoaderVisible } = useSelector((store: IStore) => store.main);


    useLayoutEffect(() => {
        setAppHeight();
        window.addEventListener('resize', setAppHeight);

        return () => window.removeEventListener('resize', setAppHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


      const setAppHeight = () => {
        const doc = document.documentElement;
        const windowHeight = window.innerHeight;
        const headerHeight = document.querySelector('.header')?.clientHeight || 0;
        const appHeight = windowHeight - headerHeight;

        doc.style.setProperty('--app-height', `${appHeight}px`);
      };


    return (
        <>

            <article className={`${((isMenuOpen || isThemesOpen) && window.innerWidth < 1024) ? 'is-blurred' : ''}`}>
                <h1 className='sr-only'>Newswire</h1>
                {isLoaderVisible && <Loader />}
                <div className='main'>
                    <Header />
                    <Menu  type='menu' />
                    <Themes type='themes' />
                    <section className={`section section--main js-main`}>
                        <div className='section__gradient js-section-gradient'></div>
                        <div className='section__lines js-section-lines'></div>
                        {children}
                    </section>
                </div>
            </article>
        </>

    );

}


export default Layout;