import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as StoreProvider } from 'react-redux';

import App from './App';

// global styles
import 'style/main.scss';

// redux store
import { store } from 'store/store';
import { BrowserRouter as Router } from 'react-router-dom';
//sentry
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

Sentry.init({
  dsn: "https://ebf4d192e7494b18b5f96a478d60ae35@o1125807.ingest.sentry.io/6168185",
  integrations: [new Integrations.BrowserTracing()],
  //percentage, adjust lower in prod
  tracesSampleRate: 1.0,
});



ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <Router>
        <App />
      </Router>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
