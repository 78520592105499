/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentType, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';


import { wait } from 'utils/all';


import { addNews, setNewsLoaded } from 'store/actionCreators/news';

import { ITheme } from 'components/Themes/Themes.interfaces';
import { themes } from 'data/test';



interface INewsAPI {
    Title: string;
    ['Source url']: string;
    Location: string;
    Latlon: number[];
    [key: string]: any;
}



export function withNews<T>(Component: ComponentType<T>) {
    const WrappedComponent = (props: T) => {

        const dispatch = useDispatch();


        const loadNews = async (theme: ITheme) => {

            try {
                const themeURL = theme.id.replace('_', '%20');
                const { data } = await axios.get(`https://api.e7e.dev/news/retrieve?daysback=10&themes=${themeURL}`)


                const formattedData = data.map((item: INewsAPI) => ({
                    id: uuidv4(),
                    title: item.Title,
                    source_url: item['Source url'],
                    location: item.Location,
                    latlon: item.Latlon,
                }));

                dispatch(
                    addNews(formattedData, theme)
                );

            } catch(err) {
                throw new Error((err as any));
            }

        }




        useEffect(() => {

            (async () => {

                if (themes) {
                    await Promise.all([...themes].map((item) => loadNews(item)));
                    await wait(1100)

                    dispatch(
                        setNewsLoaded()
                    );

                }

            })()

        }, []);


        return <Component {...props} />

    }

    return WrappedComponent;
}