import { FC, useEffect, useRef, memo } from 'react';
import gsap from 'gsap';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from 'store/types/store';
import { toggleMenu } from 'store/actionCreators/main';


import twitter from 'assets/svg/twitter.svg';
import linkedin from 'assets/svg/linkedin.svg';
import logo from 'assets/images/Logo.png';

import { NavLink } from 'react-router-dom';

interface IMenu {
    type: string;
}


const Menu: FC<IMenu> = ({ type }) => {


    const dispatch = useDispatch();
    const { isMenuOpen } = useSelector((store: IStore) => store.main);




    useEffect(() => {
        animateExpansion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuOpen]);


    const menuFooterHeight = useRef() as any;
    const menuListHeight = useRef() as any;


    const handleNavLinkClick = () => {
        dispatch(toggleMenu(false));
    }



    const animateExpansion = (): void => {

        menuFooterHeight.current = document.querySelector('.menu__footer')?.clientHeight;
        menuListHeight.current = document.querySelector('.menu__list')?.clientHeight;


        gsap.set('.expand--menu', {
            opacity: isMenuOpen ? 1 : 0,
            delay: isMenuOpen ? 0 : 0.7,
        });
        gsap.to('.expand--menu', {
            height: isMenuOpen ? `${menuFooterHeight.current + menuListHeight.current}px` : 0,
            duration: 0.8,
            ease: 'power2.inOut',
        });

    };



    return (
        <div className={`${type} expand expand--${type}`}>
            <ul className='menu__list'>
                <li><NavLink to='/' onClick={handleNavLinkClick} className={({ isActive }) => isActive ?  'active' : ''}>Newswire</NavLink></li>
                <li><NavLink to='/sources' onClick={handleNavLinkClick} className={({ isActive }) => isActive ?  'active' : ''}>Sources</NavLink></li>
                <li><NavLink to='/about' onClick={handleNavLinkClick} className={({ isActive }) => isActive ?  'active' : ''}>About</NavLink></li>
            </ul>
            <div className='menu__footer'>
                <div className='menu__footer--left'>
                    Produced by <a rel='noreferrer' href='https://earthrise.media/' target='_blank'>Earthrise Media</a>
                    <img src={logo} alt='Logo' />
                </div>
                <div className='menu__footer--right'>
                    <a rel='noreferrer' href='https://twitter.com/earthrisemedia' target='_blank'><span className='sr-only'>link to twitter</span><img src={twitter} alt='' /></a>
                    <a rel='noreferrer' href='https://www.linkedin.com/company/earthrise-media' target='_blank'><span className='sr-only'>link to linkedin</span><img src={linkedin} alt='' /></a>
                    {/* <a rel='noreferrer' href='https://www.facebook.com/' target='_blank'><img src={fb} alt=''/></a> */}
                </div>
            </div>
        </div>
    );
}

export default memo(Menu);
