import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { themeReducer } from './reducers/themeReducer';
import { newsReducer } from './reducers/newsReducer';
import { mainReducer } from './reducers/mainReducer';

const composeEnhancers = typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                         ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;


const enhancer = composeEnhancers(
    applyMiddleware(thunk)
);


const rootReducer = combineReducers({
        theme: themeReducer,
        news: newsReducer,
        main: mainReducer
    });


export const store =  createStore(rootReducer, enhancer);
