



export interface IDateObject {
    date?: string,
    day?: string,
    hour?: string,
    pm?: boolean,
}

export const formatDate = (dateObject: Date): IDateObject => {

    let result = {
        day: '',
        date: '',
        hour: '',
        pm: false
    }

    switch (dateObject.getDay()) {
        case 0:
        result.day = 'Sunday'
        break;
        case 1:
        result.day = 'Monday'
        break;
        case 2:
        result.day = 'Tuesday'
        break;
        case 3:
        result.day = 'Wednesday'
        break;
        case 4:
        result.day = 'Thursday'
        break;
        case 5:
        result.day = 'Friday'
        break;
        case 6:
        result.day = 'Saturday'
        break;
    }

    const dayOfMonth = String(dateObject.getDate());
    let month: string = '';

    switch (dateObject.getMonth()) {
        case 0:
        month = 'Jan';
        break;

        case 1:
        month = 'Feb';
        break;

        case 2:
        month = 'Mar';
        break;

        case 3:
        month = 'Apr';
        break;

        case 4:
        month = 'May';
        break;

        case 5:
        month = 'Jun';
        break;

        case 6:
        month = 'Jul';
        break;

        case 7:
        month = 'Aug';
        break;

        case 8:
        month = 'Sep';
        break;

        case 9:
        month = 'Oct';
        break;

        case 10:
        month = 'Nov';
        break;

        case 11:
        month = 'Dec';
        break;
    }

    result.date = dayOfMonth.concat(' ', month);

    let minutes = '';
    let hours = '';

    if (dateObject.getMinutes() < 10) {
        minutes = `0${dateObject.getMinutes()}`;
    }
    else {
        minutes = String(dateObject.getMinutes());
    }
    if (dateObject.getHours() > 12) {
        hours = `0${dateObject.getHours() % 12}`;
        result.pm = true;
    }
    else {
        if (dateObject.getHours() < 10) {
        hours = `0${dateObject.getHours()}`;
        }
        else hours = String(dateObject.getHours());
        result.pm = false;
    }

    result.hour = hours.concat(':', minutes);

    return result;
};






export const isInViewport = (elements: HTMLElement[] | NodeListOf<Element> | Element[]): HTMLElement => {

    let elementInViewport: any = elements[0];

    for (let item of Array.from(elements)) {

        const rect = item.getBoundingClientRect();
        const { top, bottom } = rect;

        if ( top <=  window.innerHeight - (window.innerHeight / 3) && bottom >= window.innerHeight - (window.innerHeight / 2)) {
            elementInViewport = item;
        }

    }

    return elementInViewport;
}





export const formatLeadIndex = (index: number): string => {
    let resultString = '';
    if (index === 0) {
        resultString = '1st';
    } else if (index === 1) {
        resultString = '2nd';
    } else if (index === 2) {
        resultString = '3rd';
    } else {
        resultString = `${index + 1}th`;
    }
    return resultString;
}



export const wait = (num: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), num)
    })
}