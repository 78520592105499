/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useRef, useState, FC } from 'react';
import gsap from 'gsap/all';


import { useDispatch, useSelector } from 'react-redux';


import EarthLoaderImage from 'assets/images/earth-loader.png';
// import EarthriseLogo from 'assets/images/Logo.png';
import { ReactComponent as EarthriseLogo } from 'assets/svg/logo_newswire.svg';

import { ILoader } from './Loader.interfaces';

import * as LoaderAnimation from './Loader.animations';
import { IStore } from 'store/types/store';
import { toggleLoader } from 'store/actionCreators/main';



const Loader: FC<ILoader> = () => {


    const dispatch = useDispatch();
    const { items, isLoaded } = useSelector((store: IStore) => store.news);

    const [count, setCount] = useState({ value: 0 });
    const numObj = useRef({
        val: 0,
        currentVal: 0,
    });
    const tl = gsap.timeline({ paused: false });


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        isLoaded && LoaderAnimation.animateOut({ onComplete: () => dispatch(toggleLoader()) });
    }, [isLoaded]);



    useEffect(() => {
        let val = 0;
        for (let [key, value] of Object.entries(items)) {
            val += value.amount;
            let foo = key;
        }

        numObj.current.val = val;

        tl.fromTo(numObj.current, {
            currentVal: numObj.current.currentVal,
        }, {
            currentVal: numObj.current.val,
            onUpdate: () => {
                setCount({ value: numObj.current.currentVal });
            },
            roundProps: 'currentVal',
            duration: .8,
            ease: 'none',
        });

        return () => { tl.kill() };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useLayoutEffect(() => {
        LoaderAnimation.animateIn();
    }, []);

    return (
        <div className='loader js-loader'>
            <h1 className='loader__title js-loader-title'>
                <span className='line line--top js-line-top'></span>
                <span>Today's</span><br/>Environmental<br/>News
                <span className='line line--bottom js-line-bottom'></span>
            </h1>
            <div className='loader__globe js-loader-globe'>
                <img src={EarthLoaderImage} alt='' />
            </div>
            <div className='loader__footer js-loader-footer'>
                <p className='loader__footer--text'>Loading {count.value} news</p>
                <div className='loader__footer--line'></div>
                {/* <img src={EarthriseLogo} alt='Earthrise logo' /> */}
                <EarthriseLogo />
            </div>
        </div>
    );
};

export default Loader;
