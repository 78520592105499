import * as MAIN_ACTIONS from 'store/actions/main';


export const toggleLoader = (value: boolean | undefined = undefined) => ({
    type: MAIN_ACTIONS.TOGGLE_LOADER,
    payload: {
        value: value
    }
});

export const toggleMenu = (value: boolean | undefined = undefined) => ({
    type: MAIN_ACTIONS.TOGGLE_MENU,
    payload: {
        value: value
    }
});

export const toggleThemes = (value: boolean | undefined = undefined) => ({
    type: MAIN_ACTIONS.TOGGLE_THEMES,
    payload: value
});


export const setCurrentTheme = (value: string) => ({
    type: MAIN_ACTIONS.SET_THEME,
    payload: value,
});
