import * as MAIN_ACTIONS from 'store/actions/main';



export interface IMain {
    isMenuOpen: boolean;
    isLoaderVisible: boolean;
    isThemesOpen: boolean;
    currentCat: string;
}



const initialState: IMain = {
    isMenuOpen: false,
    isLoaderVisible: true,
    isThemesOpen: false,
    currentCat: '',
};


const isBoolean = (val: any) => typeof val === 'boolean';



export const mainReducer = (state: any = initialState, action: any) => {

    switch(action.type) {


        case MAIN_ACTIONS.TOGGLE_MENU:
            return {
                ...state,
                isMenuOpen: isBoolean(action.payload) ?  action.payload : !state.isMenuOpen
            }


        case MAIN_ACTIONS.TOGGLE_LOADER:
            return {
                ...state,
                isLoaderVisible: isBoolean(action.payload) ?  action.payload : !state.isLoaderVisible
            }


        case MAIN_ACTIONS.TOGGLE_THEMES:
            return {
                ...state,
                isThemesOpen: isBoolean(action.payload) ? action.payload : !state.isThemesOpen
            }


        case MAIN_ACTIONS.SET_THEME:
            return {
                ...state,
                currentCat: action.payload,
            };


        default:
            return state;


    }

}