import { memo, useRef, useState, useEffect, FC } from 'react';
import * as THREE from 'three';
import { gsap } from 'gsap';
import { useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import GUI from 'lil-gui';


// utils
import { isInViewport, wait } from 'utils/all';
import * as GlobeUtils from './Globe.utils';

// assets
import EarthTexture from 'assets/images/earth.jpg';
import HighBumpImage from 'assets/images/earth-high.jpg';

import EarthTextureMobile from 'assets/images/earth_m.jpg';
import HighBumpImageMobile from 'assets/images/earth-high_m.jpg';

import { IStore } from 'store/types/store';



interface IGlobe { }


const Globe: FC<IGlobe> = () => {

    const news = useSelector((store: IStore) => store.news);
    const { isLoaded, latlon, limitCounter } = useSelector((store: IStore) => store.news);
    const { currentCat } = useSelector((store: IStore) => store.main);


    const globeWrapperRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const tooltipDiv = useRef<HTMLDivElement>(null);



    const options = useRef({
        rotationMod: 0.0015,
        colorObj: {
            r: 0,
            g: 0,
            b: 0
        },
        radius: 0,
        isGlobeMoving: false,
        isSpotActive: false,
        isSpotOver: false,
        isZoomed: false,
        isDesktop: window.innerWidth > 1024,
        currentTheme: '',
        elements: {
            camera: new THREE.PerspectiveCamera(),
            scene: new THREE.Scene(),
            hotspotsGroup: new THREE.Group(),
            mainGroup: new THREE.Group(),
            wrapGroup: new THREE.Group(),
            globeGroup: new THREE.Group(),
        },
        sizes: {
            width: 1024,
            height: 1000
        },
        colorObject: {
            conflict: { r: 159 / 255, g: 156 / 255, b: 150 / 255 },
            construction: { r: 80 / 255, g: 123 / 255, b: 111 / 255 },
            energy: { r: 248 / 255, g: 103 / 255, b: 63 / 255 },
            wildlands: { r: 121 / 255, g: 116 / 255, b: 255 / 255 },
            pollution: { r: 169 / 255, g: 143 / 255, b: 98 / 255 },
            fire: { r: 255 / 255, g: 116 / 255, b: 116 / 255 },
            flood: { r: 206 / 255, g: 206 / 255, b: 126 / 255 },
            glaciers: { r: 126 / 255, g: 178 / 255, b: 206 / 255 },
            oceans: { r: 126 / 255, g: 193 / 255, b: 206 / 255 },
            water: { r: 52 / 255, g: 60 / 255, b: 198 / 255 },
            climate_crisis: { r: 207 / 255, g: 158 / 255, b: 158 / 255 },
        },
        colorObjectString: {
            conflict: '159, 156, 150',
            construction: '80, 123, 111',
            energy: '248, 103, 63',
            wildlands: '121, 116, 255',
            pollution: '169, 143, 98',
            fire: '255, 116, 116',
            flood: '206, 206, 126',
            glaciers: '126, 178, 206',
            oceans: '126, 193, 206',
            water: '52, 60, 198',
            climate_crisis: '207, 158, 158',
        },
    });


    // const prevString = useRef('');
    const tooltipChildren = gsap.utils.selector(tooltipDiv);


    const [isTooltip, setTooltip] = useState(false);
    const [tooltipData, setTooltipData] = useState({
        id: '',
        title: '',
        source_url: '',
        location: '',
        x: 0,
        y: 0,
    });

    const zoomIn = (camera: THREE.PerspectiveCamera, fast: boolean): Promise<void> => {
        return new Promise((resolve) => {
            if (options.current.isDesktop) {
                options.current.isZoomed = true;
                gsap.to(camera, {
                    zoom: 1.2,
                    duration: fast ? 0.4 : 0.75,
                    ease: 'sine.inOut',
                    onUpdate: () => {
                        camera.updateProjectionMatrix();
                    },
                    onComplete: resolve,
                });
            } else {
                resolve();
            }

        });
    };

    const zoomOut = (camera: THREE.PerspectiveCamera) => {
        if (options.current.isDesktop) {
            options.current.isZoomed = false;
            gsap.to(camera, {
                zoom: 1,
                duration: 0.4,
                ease: 'sine.inOut',
                onUpdate: () => {
                    camera.updateProjectionMatrix();
                },
            });
        }
    };




    useEffect(() => {

        if (!isLoaded) { return; }

        // DOM Elements
        const wrapper = globeWrapperRef.current!;
        const canvas = wrapper.querySelector('canvas') as HTMLCanvasElement;

        if (!canvas) { return; }

        // update globeOptions
        const globeOptions = options.current;

        let {
            sizes,
            colorObj,
            colorObject,
            colorObjectString,
            elements,
            isDesktop,
            radius,
        } = globeOptions;

        let { scene, globeGroup, mainGroup, wrapGroup, hotspotsGroup } = elements;

        sizes = {
            width: wrapper.clientWidth,
            height: (window.innerWidth < 1024) ? wrapper.clientHeight : window.innerHeight - 44,
        };

        options.current.isGlobeMoving = true;



        // Scene is defined and mainGroup in useRef object


        // Groups
        mainGroup.add(wrapGroup);
        wrapGroup.add(hotspotsGroup);

        scene.add(mainGroup);


        // Loading Manager
        const loadingManager = new THREE.LoadingManager();


        // Texture Loader
        const textureLoader = new THREE.TextureLoader(loadingManager);
        const earthTexture = textureLoader.load(
            isDesktop ? EarthTexture : EarthTextureMobile
        );
        const earthHeightTexture = textureLoader.load(
            isDesktop ? HighBumpImage : HighBumpImageMobile
        );

        const material = new THREE.MeshStandardMaterial({
            map: earthTexture,
            displacementMap: earthHeightTexture,
            displacementScale: 1.2,
            metalness: 0.2,
            roughness: 0.7,
        });



        // Sphere Geometry
        const geometry = new THREE.SphereGeometry(10, 150, 150);
        // geometry.rotateY(-Math.PI * 0.5);
        const centerGlobe = new THREE.Vector3();
        const objectGlobe = new THREE.Mesh(geometry, material);
        const box = new THREE.Box3().setFromObject(objectGlobe);
        globeGroup = new THREE.Group();
        globeGroup.add(objectGlobe);
        box.getCenter(centerGlobe);
        objectGlobe.position.sub(centerGlobe);
        wrapGroup.add(globeGroup);
        mainGroup.position.set(0, 0, 0);
        radius = box.max.x * 1.02;

        if (window.location.search.indexOf('debug') > 0) {
            const gui = new GUI();
            const scale = {value: 0.99};

            const waterMAterial = new THREE.MeshStandardMaterial({color: 0x497383});
            const waterGlobe = new THREE.Mesh(geometry, waterMAterial);
            waterGlobe.scale.set(scale.value, scale.value, scale.value);
            waterGlobe.position.y = -0.001;
            globeGroup.add(waterGlobe);

            gui.add(scale, 'value')
                .name('waterscale')
                .min(0.995)
                .max(1.05)
                .onChange(() => { waterGlobe.scale.set(scale.value,scale.value,scale.value) })
                .step(0.0001);
        }

        // Lights

        // Ambient Light
        const ambientLight = new THREE.AmbientLight(0xffffff, .9);

        // Directional Light
        const directionalLight = new THREE.DirectionalLight(0x73c1ce, 1);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.set(124, 124);
        directionalLight.shadow.camera.far = 100;
        directionalLight.position.set(0, -100, 50);

        // Directional Light Top
        const directionalLightTop = new THREE.DirectionalLight(0xffffff, .55);
        directionalLightTop.position.set(0, 100, 0);

        // Light Group
        const lightGroup = new THREE.Group();
        lightGroup.add(ambientLight);
        lightGroup.add(directionalLight);
        lightGroup.add(directionalLightTop);
        scene.add(lightGroup);



        // Base camera
        const cameraZoom = isDesktop ? 500 : 520;
        const fieldOfView = 2 * Math.atan((sizes.height / 2) / cameraZoom * (180 / Math.PI));
        options.current.elements.camera = new THREE.PerspectiveCamera(fieldOfView, sizes.width / sizes.height, 10, 1000);
        options.current.elements.camera.position.set(0, 0, cameraZoom);
        scene.add(options.current.elements.camera);


        // Renderer
        const pixelRatio = Math.min(window.devicePixelRatio, 2);
        const renderer = new THREE.WebGLRenderer({
            canvas: canvasRef.current as HTMLCanvasElement,
            alpha: true,
            antialias: pixelRatio <= 1,
        });
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(pixelRatio);


        // Hotspots
        const setHotspot = (coordinates: number[], r: number, id: string, title: string, source_url: string, location: string, category: string) => {
            const cylinderRadius = isDesktop ? .3 : .35;
            const geometryH = new THREE.CylinderGeometry(cylinderRadius, cylinderRadius, .12, 10, 1);
            geometryH.rotateX(Math.PI / 2);

            const materialH = new THREE.MeshStandardMaterial({ color: 0xFF7474, roughness: 0.8, metalness: 0.4, transparent: true });
            const cylinder = new THREE.Mesh(geometryH, materialH);
            const formatedPosition = GlobeUtils.calcPosFromLatLonRad2({ lat: coordinates[0], lon: coordinates[1], radius: r + Math.random() * 0.1 }).vector3;
            cylinder.position.set(formatedPosition.x, formatedPosition.y, formatedPosition.z);
            cylinder.lookAt(new THREE.Vector3(0, 0, 0));
            cylinder.userData = { id: id, title: title, source_url: source_url, location: location, vx: coordinates[0], vy: coordinates[1], };

            gsap.fromTo(cylinder.scale, { x: 0, y: 0, z: 0 }, { x: 1, y: 1, z: 1, duration: 0.35, delay: 0.4, ease: 'power2' });

            hotspotsGroup.add(cylinder);
        };




        const loadHotspots = (category: string): void => {

            let duration = 0;

            if (!isEmpty(hotspotsGroup.children)) {
                duration = 400;

                [...hotspotsGroup.children].map(h => {
                    gsap.fromTo(h.scale, { x: 1, y: 1, z: 1 }, { x: 0, y: 0, z: 0, delay: 0.05, duration: 0.25, stagger: { amount: duration * 0.001, each: 0.1 }, ease: 'power2.out' });
                    return null;
                })


            }

            wait(duration).then(() => {
                hotspotsGroup.children = [];

                for (const key in news.items) {
                    if (Object.prototype.hasOwnProperty.call(news.items, key)) {
                        const newsItemsObj = news.items[key];
                        if (key === category) {
                            for (let index = 0; index < newsItemsObj.amount; index++) {
                                const news = newsItemsObj.newsItems[index];
                                if (index >= newsItemsObj.limit) { break; }

                                setHotspot(news.latlon, radius, news.id, news.title, news.source_url, news.location, key);
                            }
                        }
                    }
                }
            })

        };

        window.addEventListener('loadHotspots', () => {
            loadHotspots(options.current.currentTheme)
        });


        // Raycaster
        const raycaster = new THREE.Raycaster();


        // Mouse
        const mouse = new THREE.Vector2();
        let isSpotOver = false;
        options.current.isSpotActive = false;
        let hovered: THREE.Mesh[] = [];

        const onSpotOver = (x: number, y: number): void => {
            const offset = canvasRef.current!.getBoundingClientRect();
            mouse.x = (x - offset.left) / sizes.width * 2 - 1;
            mouse.y = - (y - offset.top) / sizes.height * 2 + 1;

            raycaster.setFromCamera(mouse, options.current.elements.camera);
            const intersects = raycaster.intersectObjects(hotspotsGroup.children);

            if (intersects.length > 0) {
                isSpotOver = true;
                const hotspot = intersects[intersects.length - 1];
                const hMesh = hotspot.object as THREE.Mesh;
                gsap.to(hMesh.scale, { duration: 0.35, x: 1.2, y: 1.2, z: 1.2 });
                hovered.push(hMesh);
            } else {
                isSpotOver = false;
                if (hovered.length > 0) {
                    hovered.forEach((el: THREE.Mesh) => {
                        gsap.killTweensOf(el.scale);
                        gsap.to(el.scale, { duration: 0.35, x: 1, y: 1, z: 1 });
                    });
                    hovered = [];
                }
            }
            let cursor = isSpotOver ? 'pointer' : 'default';
            wrapper.style.cursor = cursor;

        };


        const onInteraction = (x: number, y: number): void => {
            const offset = canvasRef.current!.getBoundingClientRect();
            mouse.x = (x - offset.left) / sizes.width * 2 - 1;
            mouse.y = - (y - offset.top) / sizes.height * 2 + 1;

            raycaster.setFromCamera(mouse, options.current.elements.camera);
            const intersects = raycaster.intersectObjects(hotspotsGroup.children);

            hotspotsGroup.children.forEach(hotspot => {
                const hMesh = hotspot as THREE.Mesh;
                const hMaterial = hMesh.material as THREE.MeshStandardMaterial;
                hMaterial.color.setHex(0xFF7474);
            });



            if (intersects.length > 0) {

                const hotspot = intersects[intersects.length - 1];
                const hMesh = hotspot.object as THREE.Mesh;
                options.current.isSpotActive = true;
                setTooltip(true);

                let hotspotInScreen = GlobeUtils.toScreenPosition(hotspot.object, options.current.elements.camera, canvasRef.current!.clientWidth, canvasRef.current!.clientHeight);

                if (options.current.isZoomed) {
                    GlobeUtils.setSpotActive({
                        hMesh,
                        x: hotspotInScreen.x + canvasRef.current!.getBoundingClientRect().left,
                        y: hotspotInScreen.y + canvasRef.current!.getBoundingClientRect().top,
                        isTooltip: isTooltip,
                        setTooltip: setTooltip,
                        tooltipData: tooltipData,
                        setTooltipData: setTooltipData,
                        tooltipDiv: tooltipDiv,
                        tooltipChildren: tooltipChildren,
                    });
                } else {
                    zoomIn(options.current.elements.camera, true).then(() => {
                        hotspotInScreen = GlobeUtils.toScreenPosition(hotspot.object, options.current.elements.camera, canvasRef.current!.clientWidth, canvasRef.current!.clientHeight);
                        GlobeUtils.setSpotActive({
                            hMesh,
                            x: hotspotInScreen.x + canvasRef.current!.getBoundingClientRect().left,
                            y: hotspotInScreen.y + canvasRef.current!.getBoundingClientRect().top,
                            isTooltip: isTooltip,
                            setTooltip: setTooltip,
                            tooltipData: tooltipData,
                            setTooltipData: setTooltipData,
                            tooltipDiv: tooltipDiv,
                            tooltipChildren: tooltipChildren,
                        });
                    });
                }
            } else { options.current.isSpotActive = false; }

        };

        const onMouseDown = (event: MouseEvent): void => {
            if (tooltipDiv.current) {
                const isClickInside = tooltipDiv.current.contains(event.target as Node);
                if (!isClickInside) {
                    options.current.isGlobeMoving = true;
                    setTooltip(false);
                }
            }
        };

        const onTouch = (event: TouchEvent): void => {
            if (tooltipDiv.current) {
                const isClickInside = tooltipDiv.current.contains(event.target as Node);
                if (!isClickInside) {
                    options.current.isGlobeMoving = true;
                    setTooltip(false);
                }
            }
        };


        const hideTooltip = (): void => {

            if (!options.current.isSpotActive) { return; }
            setTooltip(false);

            hotspotsGroup.children.forEach(hotspot => {
                const hMesh = hotspot as THREE.Mesh;
                const hMaterial = hMesh.material as THREE.MeshStandardMaterial;
                hMaterial.color.setHex(0xFF7474);
                // hMaterial.color.set(currentColor);
                options.current.isGlobeMoving = true;

                options.current.isSpotActive = false;
            });
        };


        const animateGlobeCenter = (): void => {
            const currentQuaternion = new THREE.Quaternion();
            currentQuaternion.copy(mainGroup.quaternion);

            const targetQuaternion = new THREE.Quaternion();
            const euler = new THREE.Euler(0, mainGroup.rotation.y + (Math.PI * 2), 0);
            targetQuaternion.setFromEuler(euler);

            const animatedQuaternion = new THREE.Quaternion();
            const animationProgress = {
                current: 0,
            };

            gsap.to(animationProgress, {
                current: 1,
                onUpdate: () => {
                    wrapGroup.rotation.y = (Math.PI * 2) * animationProgress.current;
                    animatedQuaternion.slerpQuaternions(currentQuaternion, targetQuaternion, animationProgress.current);
                    mainGroup.quaternion.copy(animatedQuaternion);
                },
                duration: 1.2,
                ease: 'expo.inOut',
            });
        }


        const onClick = (event: MouseEvent): void => {
            onInteraction(event.clientX, event.clientY);
        };

        const onMouseMove = (e: MouseEvent): void => {
            onSpotOver(e.clientX, e.clientY);
        };

        let isAnimating = false;

        const onGlobeZoom = (event: WheelEvent): void => {
            event.preventDefault();
            if (isAnimating) { return; }

            hideTooltip();
            if (event.deltaY > 0 && options.current.elements.camera.zoom === 1) {
                isAnimating = true;
                gsap.fromTo(options.current.elements.camera, {
                    zoom: 1,
                }, {
                    zoom: 1.7,
                    duration: 0.75,
                    ease: 'sine.inOut',
                    onUpdate: () => {
                        options.current.elements.camera.updateProjectionMatrix();
                    },
                    onComplete: () => {
                        // controls.enabled = true;
                        isAnimating = false;

                        return;
                    },
                });
            }

            if (event.deltaY < 0 && options.current.elements.camera.zoom > 1) {
                isAnimating = true;

                gsap.fromTo(options.current.elements.camera, {
                    zoom: 1.7,
                }, {
                    zoom: 1,
                    duration: 0.75,
                    ease: 'sine.inOut',
                    onUpdate: () => {
                        options.current.elements.camera.updateProjectionMatrix();
                    },
                    onComplete: () => {
                        // controls.enabled = true;
                        isAnimating = false;

                        return;
                    },
                });
            }
        };










        const onResize = () => {
            // Update sizes
            sizes.width = wrapper.clientWidth;
            sizes.height = window.innerHeight - 44;

            // Update camera
            options.current.elements.camera.aspect = sizes.width / sizes.height;
            options.current.elements.camera.updateProjectionMatrix();

            // Update renderer
            renderer.setSize(sizes.width, sizes.height);
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        };



        // Controls
        const drag = 0.95;
        const minDelta = 0.05;
        let mouseDown = false;
        let rotateStartPoint = new THREE.Vector3(0, 0, 1);
        let rotateEndPoint = new THREE.Vector3(0, 0, 1);

        let curQuaternion = null;
        const rotationSpeed = isDesktop ? 1.75 : .5;
        let lastMoveTimestamp = new Date();
        const moveReleaseTimeDelta = 50;

        let startPoint = {
            x: 0,
            y: 0,
        };

        let deltaX = 0;
        let deltaY = 0;

        function onDocumentMouseDown(event: MouseEvent): void {
            event.preventDefault();
            const offset = canvasRef.current!.getBoundingClientRect();

            document.addEventListener('mousemove', onDocumentMouseMove, false);
            document.addEventListener('mouseup', onDocumentMouseUp, false);

            mouseDown = true;

            startPoint = {
                x: event.clientX - offset.left,
                y: event.clientY - offset.top,
            };

            rotateStartPoint = rotateEndPoint = projectOnTrackball(0, 0);
        }

        function onDocumentMouseMove(event: MouseEvent): void {
            const offset = canvasRef.current!.getBoundingClientRect();

            deltaX = (event.clientX - offset.left) - startPoint.x;
            deltaY = (event.clientY - offset.top) - startPoint.y;

            handleRotation();

            startPoint.x = (event.clientX - offset.left);
            startPoint.y = (event.clientY - offset.top);

            lastMoveTimestamp = new Date();
        }

        function onDocumentMouseUp(event: MouseEvent): void {
            if (new Date().getTime() - lastMoveTimestamp.getTime() > moveReleaseTimeDelta) {
                const offset = canvasRef.current!.getBoundingClientRect();
                deltaX = (event.clientX - offset.left) - startPoint.x;
                deltaY = (event.clientY - offset.top) - startPoint.y;
            }

            mouseDown = false;

            document.removeEventListener('mousemove', onDocumentMouseMove, false);
            document.removeEventListener('mouseup', onDocumentMouseUp, false);
        }

        function onDocumentTouchStart(event: TouchEvent): void {

            document.addEventListener('touchmove', onDocumentTouchMove, false);
            document.addEventListener('touchend', onDocumentTouchEnd, false);

            mouseDown = true;

            startPoint = {
                x: event.touches[0].clientX,
                y: event.touches[0].clientY,
            };

            rotateStartPoint = rotateEndPoint = projectOnTrackball(0, 0);


        }

        function onDocumentTouchMove(event: TouchEvent): void {
            deltaX = event.touches[0].clientX - startPoint.x;
            deltaY = event.touches[0].clientY - startPoint.y;

            handleRotation();

            startPoint.x = event.touches[0].clientX;
            startPoint.y = event.touches[0].clientY;

            lastMoveTimestamp = new Date();
        }

        function onDocumentTouchEnd(): void {
            mouseDown = false;

            document.removeEventListener('touchmove', onDocumentTouchMove, false);
            document.removeEventListener('touchend', onDocumentTouchEnd, false);
        }

        function projectOnTrackball(touchX: number, touchY: number): THREE.Vector3 {
            let mouseOnBall = new THREE.Vector3();

            mouseOnBall.set(
                clamp(touchX / (sizes.width / 2), -1, 1), clamp(-touchY / (sizes.height / 2), -1, 1),
                0.0
            );

            let length = mouseOnBall.length();

            if (length > 1.0) {
                mouseOnBall.normalize();
            } else {
                mouseOnBall.z = Math.sqrt(1.0 - length * length);
            }

            return mouseOnBall;
        }

        function rotateMatrix(rotateStart: THREE.Vector3, rotateEnd: THREE.Vector3): THREE.Quaternion {
            let axis = new THREE.Vector3(),
                quaternion = new THREE.Quaternion();

            let angle = Math.acos(rotateStart.dot(rotateEnd) / rotateStart.length() / rotateEnd.length());

            if (angle) {
                axis.crossVectors(rotateStart, rotateEnd).normalize();
                angle *= rotationSpeed;
                quaternion.setFromAxisAngle(axis, angle);
            }
            return quaternion;
        }

        function clamp(value: number, min: number, max: number): number {
            return Math.min(Math.max(value, min), max);
        }

        const handleRotation = () => {
            rotateEndPoint = projectOnTrackball(deltaX, deltaY);

            const rotateQuaternion = rotateMatrix(rotateStartPoint, rotateEndPoint);
            curQuaternion = mainGroup.quaternion;
            curQuaternion.multiplyQuaternions(rotateQuaternion, curQuaternion);
            curQuaternion.normalize();

            mainGroup.setRotationFromQuaternion(curQuaternion);

            rotateEndPoint = rotateStartPoint;
        };

        document.addEventListener('mousedown', onDocumentMouseDown, false);
        document.addEventListener('touchstart', onDocumentTouchStart, false);

        // handle scroll
        (async () => {

            // wait for newsWrappers elements
            await wait(1500);

            const mainSection = document.querySelector('.section--main') as HTMLElement;
            const bottomGradient = document.querySelector('.js-section-gradient') as HTMLElement
            let newsWrappers = document.querySelectorAll('.js-news-wrapper');
            let isInit = true;


            const onScroll = () => {

                if (document.body.classList.contains('is-news-hidden')) { return; }

                newsWrappers = document.querySelectorAll('.js-news-wrapper');
                const elementInViewport = isInViewport(newsWrappers);
                const category = elementInViewport.dataset.category || '';

                hideTooltip();
                if (options.current.isZoomed) {
                    zoomOut(options.current.elements.camera);
                }


                if (isDesktop && category === options.current.currentTheme) { return; }

                const colorObjectStringValue = (colorObjectString as any)[category];
                const colorObjectValue = (colorObject as any)[category];
                if (!isDesktop) {
                    if (options.current.currentTheme.length === 0) { options.current.currentTheme = category; }
                }
                GlobeUtils.updateCategory(bottomGradient, colorObjectStringValue, colorObj, colorObjectValue, isDesktop ? category : options.current.currentTheme, loadHotspots, animateGlobeCenter, isInit);
                isInit = false;
                options.current.currentTheme = category;
            };

            onScroll();


            mainSection.addEventListener('scroll', onScroll);

        })()

        window.addEventListener('resize', onResize);
        window.addEventListener('click', onClick);
        window.addEventListener('touchstart', onTouch);
        window.addEventListener('mousedown', onMouseDown);
        wrapper.addEventListener('mousemove', onMouseMove);



        // Animation
        let raf = 0;
        const axis = new THREE.Vector3(0, 1, 0);

        const tick = () => {


            if (!mouseDown) {

                if (!options.current.isSpotActive) {
                    mainGroup.rotateOnAxis(axis, options.current.rotationMod);
                    if (deltaX < -minDelta || deltaX > minDelta) {
                        deltaX *= drag;
                    } else {
                        deltaX = 0;
                    }

                    if (deltaY < -minDelta || deltaY > minDelta) {
                        deltaY *= drag;
                    } else {
                        deltaY = 0;
                    }

                    handleRotation();
                }

            }



            directionalLight.color.setRGB(colorObj.r, colorObj.g, colorObj.b);
            // mainGroup.rotation.y = Math.PI * 2 * rotationMod;


            renderer.render(scene, options.current.elements.camera);

            raf = window.requestAnimationFrame(tick);
        };

        tick();


        return () => {
            wrapper.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('click', onClick);
            window.removeEventListener('touchstart', onTouch);
            window.removeEventListener('mousedown', onMouseDown);
            wrapper.removeEventListener('wheel', onGlobeZoom);
            window.removeEventListener('resize', onResize);
            document.removeEventListener('mousedown', onDocumentMouseDown);
            document.removeEventListener('touchstart', onDocumentTouchStart);
            window.cancelAnimationFrame(raf);
            raf = 0;
            GlobeUtils.clearThree(scene);
            // console.log('Globe unmount');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);




    useEffect(() => {

        const optionsObj = options.current;
        const { mainGroup } = optionsObj.elements;
        const { hotspotsGroup } = optionsObj.elements;
        // const { camera } = optionsObj.elements;
        const { rotationMod } = optionsObj;

        if (latlon && mainGroup) {
            optionsObj.isSpotActive = true;

            GlobeUtils.animateGlobeTo({
                lat: latlon[0],
                lon: latlon[1],
                isGlobeMoving: options.current.isGlobeMoving,
                globe: mainGroup,
                hotspotsGroup: hotspotsGroup,
                rotationMod: rotationMod,
                isTooltip: isTooltip,
                setTooltip: setTooltip,
                tooltipData: tooltipData,
                setTooltipData: setTooltipData,
                tooltipDiv: tooltipDiv,
                tooltipChildren: tooltipChildren,
            });
            zoomIn(options.current.elements.camera, true);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latlon]);

    useEffect(() => {
        options.current.currentTheme = currentCat;
        const mainSection = document.querySelector('.section--main') as HTMLElement;
        mainSection.dispatchEvent(new CustomEvent('scroll'));

    }, [currentCat]);



    useEffect(() => {
        window.dispatchEvent(new CustomEvent('loadHotspots'));

    }, [limitCounter]);



    return (
        <div className='globe__wrapper' ref={globeWrapperRef} id='globe-wrapper'>
            <div className='globe'>
                <canvas ref={canvasRef}></canvas>
                {isTooltip && (
                    <>
                        <div className='tooltip' style={{ left: tooltipData.x, top: tooltipData.y }} ref={tooltipDiv}>
                            <div className='tooltip__curve js-curve'></div>
                            <div className='tooltip__wrap js-text-wrap'>
                                <div className='tooltip__texts'>
                                    <a href={tooltipData.source_url} target='_blank' rel='noreferrer' className='link'><span className='sr-only'>go to article</span></a>
                                    <p className='title'>{tooltipData.title}</p>
                                    <p className='location'>{tooltipData.location}</p>
                                </div>
                                <div className='tooltip__linkicon'><svg viewBox='0 0 21.04 21.09'>
                                    <g className='arr'>
                                        <polygon points='16.19 11.47 15.59 11.47 15.59 5.46 9.59 5.46 9.59 4.87 16.19 4.87 16.19 11.47' />

                                        <rect x='5.68' y='9.07' width='12.03' height='0.6' transform='translate(-3.2 11.03) rotate(-45.07)' />
                                    </g>
                                    <polygon points='12.59 21.09 0 21.09 0 8.47 7.14 8.47 7.14 9.07 0.6 9.07 0.6 20.5 11.99 20.5 11.99 13.94 12.59 13.94 12.59 21.09' />

                                </svg></div>
                                <div className='tooltip__dot js-dot'></div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(Globe);
