/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentType, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { addThemes } from 'store/actionCreators/theme';

import { themes } from 'data/test';

export function withThemes<T>(Component: ComponentType<T>) {
    const WrappedComponent = (props: T) => {

        const dispatch = useDispatch();

        useEffect(() => {

            dispatch(
                addThemes(themes)
            )

        }, []);


        return <Component {...props} />;

    }

    return WrappedComponent;
}