import { INewsItem, INews } from "components/News/News.interfaces";

import * as NEWS_ACTIONS from 'store/actions/news';

export interface INewsAction {
    type: string;
    payload: {
        cat: string;
        newsItems?: INewsItem[];
        limit?: number;
        percent?: number;
        amount?: number;
        isActive?: boolean;
        name?: string;
        isLoaded?: boolean;
    };
}



const initialState: INews = {
    items: {},
    isLoaded: false,
    latlon: [0, 0],
    limitCounter: 0,
};


export const newsReducer = (state = initialState, action: any) => {

    switch (action.type) {



        case NEWS_ACTIONS.ADD_NEWS:
            return { ...state, items: {
                ...state.items,
                [action.payload.cat] : {
                    newsItems: action.payload.newsItems as INewsItem[],
                    limit: action.payload.limit as number,
                    percent: action.payload.percent as number,
                    amount: action.payload.amount as number,
                    isActive: action.payload.isActive as boolean,
                    name: action.payload.name as string,
                },
            }};


        case NEWS_ACTIONS.SET_NEWS_PERCENT:
            for (const category in state.items) {
                if (Object.prototype.hasOwnProperty.call(state.items, category)) {
                    const element = state.items[category];
                    if (category === action.payload.cat) {
                        element.percent = action.payload.percent as number;
                    }
                }
            }
            return {...state, state};



        case NEWS_ACTIONS.TOGGLE_NEWS_ACTIVE:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.payload.cat]: {
                        ...state.items[action.payload.cat],
                        isActive: !state.items[action.payload.cat].isActive,
                    },
                },
            };



        case NEWS_ACTIONS.SET_ALL_NEWS_ACTIVE:
            for (const category in state.items) {
                if (Object.prototype.hasOwnProperty.call(state.items, category)) {
                    const element = state.items[category];
                    element.isActive = true;
                }
            }
            return {...state, state};



        case NEWS_ACTIONS.SET_ALL_NEWS_NOT_ACTIVE:
            for (const category in state.items) {
                if (Object.prototype.hasOwnProperty.call(state.items, category)) {
                    const element = state.items[category];
                    element.isActive = false;
                }
            }
            return {...state, state};


        case NEWS_ACTIONS.INCREASE_LIMIT:
            for (const category in state.items) {
                if (Object.prototype.hasOwnProperty.call(state.items, category)) {
                    const element = state.items[category];
                    if (category === action.payload.cat) {
                        element.limit += 10;
                        state.limitCounter! += 1;
                    }
                }
            }
            return {...state, state};



        case NEWS_ACTIONS.RESET_LIMIT:
            for (const category in state.items) {
                if (Object.prototype.hasOwnProperty.call(state.items, category)) {
                    const element = state.items[category];
                    element.limit = 10;
                }
            }
            return {...state, state};



        case NEWS_ACTIONS.SET_NO_LIMIT:
            for (const category in state.items) {
                if (Object.prototype.hasOwnProperty.call(state.items, category)) {
                    const element = state.items[category];
                    if (category === action.payload.cat) {
                        element.limit = element.amount;
                    }
                }
            }
            return {...state, state};



        case NEWS_ACTIONS.SET_LOADED:
            return {
                ...state,
                isLoaded: true,
            };



        case NEWS_ACTIONS.SET_CURRENT_LATLON:
            return {
                ...state,
                latlon: action.payload.value
            }



        default:
            return state;
    }

}