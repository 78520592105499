import { FC, memo } from 'react';

import News from 'components/News/News';

interface IHome {}

const Home: FC<IHome> = () => (
    <div className='home'>
        <News />
    </div>
);



export default memo(Home);
