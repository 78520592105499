/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';

import NewsItem from 'components/NewsItem/NewsItem';
import NewsInfo from 'components/NewsInfo/NewsInfo';

import { IStore } from 'store/types/store';
import { increaseLimit } from 'store/actionCreators/news';

import { formatLeadIndex } from 'utils/all';


interface INewsFC {}

const News: FC<INewsFC> = () => {


    const [sortedNews, setSortedNews] = useState<any[]>([]);
    const [totalNews, setTotalNews] = useState<number>(0);


    const dispatch = useDispatch();
    const { items, isLoaded } = useSelector((store: IStore) => store.news);


    const handleIncreaseLimit = (value: any) => {
        dispatch(increaseLimit(value))
    }


    const sortNewsItem = (a: any, b: any) => {
        return b.data.amount - a.data.amount;
    }


    useEffect(() => {
        if (!isEmpty(items) && isLoaded) {
            let allNews: number = 0;

            const news = Object.entries(items)
                .map((item: any) => {
                    const name = item[0];
                    const newsContent = item[1];

                    allNews += newsContent.amount;

                    return {
                        category: name.replace(/_/, ' '),
                        id: name,
                        data: newsContent
                    }})

                .sort(sortNewsItem);

            setSortedNews(news);
            setTotalNews(allNews);
        }


    }, [isLoaded]);





    return (
        <div className='news'>
            {
                // 'any' type because themes array is from API - can't define unique interface
                isLoaded && sortedNews.map((theme: any, i: number) => {

                    const { category, data, id } = theme;
                    const { amount, newsItems, limit } = data;
                    const percent = Math.ceil(amount / totalNews * 100) || 100;
                    const leadingNumber = formatLeadIndex(i);
                    const slicedNewsItems = [...newsItems].splice(0, limit);

                    return amount > 0 ? (
                        <div key={category}>
                            <div className="news__wrapper js-news-wrapper" data-category={id}>
                                <div className="news__title">
                                    <h2>{category}</h2>
                                </div>
                                <NewsInfo amount={amount} percent={percent} leadingNumber={leadingNumber} />
                                {slicedNewsItems.map((newsItem: any) => (
                                    <NewsItem
                                        category={id}
                                        key={newsItem.id}
                                        location={newsItem.location}
                                        title={newsItem.title}
                                        source_url={newsItem.source_url}
                                        id={newsItem.id}
                                        latlon={newsItem.latlon}
                                    />
                                ))}
                                <button
                                    className={data.limit < data.amount ? 'news__button is-visible' : 'news__button'}
                                    onClick={() => handleIncreaseLimit!(category)}>
                                    Explore more insights
                                </button>
                            </div>
                        </div>
                    ) : null
                })
            }
        </div>
    );
};



export default memo(News);
