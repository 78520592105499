import * as THEME_ACTIONS from 'store/actions/theme';
import { ITheme } from 'components/Themes/Themes.interfaces';

export const addThemes = (value: ITheme[]) => ({
    type: THEME_ACTIONS.ADD_THEMES,
    payload: {
        themesItems: value
    }
});

export const toggleActive = (cat: string) => ({
    type: THEME_ACTIONS.TOGGLE_THEMES_ACTIVE,
    payload: {
        cat: cat,
    }
});

export const setDisactive = () => ({
    type: THEME_ACTIONS.SET_THEMES_DISACTIVE
});
