import { FC, memo, useEffect } from "react";


const Sources: FC = () => {


    useEffect(() => {

        const areNewsHidden = document.body.classList.contains('is-news-hidden');
        !areNewsHidden && document.body.classList.add('is-news-hidden');

        return () => {
            document.body.classList.remove('is-news-hidden');
        }

    }, [])


    return (
        <div className="sources">
            <h1 className='title'>Sources</h1>
            <p className='content'>
                Earthrise scrapes content from 30,000 websites a day to find the world's most urgent environmental news. A multi-stage algorithmic process selects, categorizes, and locates the best of this content for inclusion in Earthrise Newswire.
                Source URLs come from <a href="https://newsapi.org/" target="_blank" rel="noreferrer">News API</a> and the <a href="https://www.gdeltproject.org/" target="_blank" rel="noreferrer">GDelt Project</a>, which monitor digital news media and news blogs from around the world.
            </p>
            <p className='content'>
                Custom machine language processing models identify environmental themes for each story. We capture impacts of the climate crisis, polluting industry, threats to wilderness, the persistence of fossil fuels, and action for a cleaner energy future. Each story is timely, local, and specific in its interests, from the resilience of a community to the ravaging of a forest. As a collection, the stories express a moving record of a planet under stress.
            </p>
            <p className='content'>
                Selected stories pass through a geolocation algorithm that retrieves latitude and longitude for each place named in the story. Then one final learned model evaluates the relevance, frequency, and precision of locations, and attempts to assign to each story a single best position on the map.
            </p>
        </div>
    );
};

export default memo(Sources);
