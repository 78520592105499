export const themes = [
    {
        'name': 'Conflict',
        'id': 'conflict',
        'isActive': false,
    },
    {
        'name': 'Construction',
        'id': 'construction',
        'isActive': false,
    },
    {
        'name': 'Energy',
        'id': 'energy',
        'isActive': false,
    },
    {
        'name': 'Wildlands',
        'id': 'wildlands',
        'isActive': false,
    },
    {
        'name': 'Pollution',
        'id': 'pollution',
        'isActive': false,
    },
    {
        'name': 'Fire',
        'id': 'fire',
        'isActive': false,
    },
    {
        'name': 'Flood',
        'id': 'flood',
        'isActive': false,
    },
    {
        'name': 'Glaciers',
        'id': 'glaciers',
        'isActive': false,
    },
    {
        'name': 'Oceans',
        'id': 'oceans',
        'isActive': false,
    },
    {
        'name': 'Water',
        'id': 'water',
        'isActive': false,
    },
    {
        'name': 'Climate crisis',
        'id': 'climate_crisis',
        'isActive': false,
    },

]