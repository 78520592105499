/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, memo, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';

import { wait } from 'utils/all';
import { useFormattedDate } from './Header.utils';

import { IStore } from 'store/types/store';
import { IHeader } from './Header.interfaces';
import { toggleMenu, toggleThemes } from 'store/actionCreators/main';

import chevron from 'assets/svg/chevron-down.svg';
import news from 'assets/svg/news.svg';
import globe from 'assets/svg/globe.svg';



const Header: FC<IHeader> = () => {

    // const [date, setDate] = useState<IDateObject>(formatDate(new Date()));

    const [date] = useFormattedDate();

    const [isNewsOpen, setIsNewsOpen] = useState(false);


    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { isMenuOpen, isThemesOpen } = useSelector((store: IStore) => store.main);


    const handleHamburgerClick = (e: MouseEvent) => {

        if (isThemesOpen) {
            dispatch(toggleThemes(false))
            wait(500).then(() => dispatch(toggleMenu()));
        }

        if (!isThemesOpen) {
            dispatch(toggleMenu());
        }


    }



    const handleSwitchButtonClick = (e: MouseEvent) => {

        if (isThemesOpen) {
            dispatch(toggleThemes(false));
            wait(500).then(() => setIsNewsOpen(!isNewsOpen))
        }

        if (!isThemesOpen) {
            setIsNewsOpen(!isNewsOpen);
        }

    }



    const handleThemesButtonClick = (e: MouseEvent) => {
        if (isMenuOpen) {
            dispatch(toggleMenu(false));
            wait(500).then(() => dispatch(toggleThemes()));
        }

        if (!isMenuOpen) {
            dispatch(toggleThemes())
        }
    }


    useEffect(() => {
        animateExpansion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMenuOpen, isThemesOpen]);



    const calculateTopOffset = (): number => {
        const themesTitleHeight: any = document.querySelector('.themes__title')?.clientHeight,
              themesListHeight: any = document.querySelector('.themes__list')?.clientHeight,
              menuFooterHeight: any = document.querySelector('.menu__footer')?.clientHeight,
              menuListHeight: any = document.querySelector('.menu__list')?.clientHeight;

        let offset: number = 0;

        if (isMenuOpen) {
            offset = menuFooterHeight + menuListHeight;
        } else if (isThemesOpen) {
           offset = themesListHeight + themesTitleHeight + 150;
        }

        return offset + 44;
    }



    const animateExpansion = (): void => {

        const sectionElement = document.querySelector('.section--main');

        if (isMenuOpen || isThemesOpen) {
            gsap.to(sectionElement, {
                height: window.innerWidth >= 1024 ? window.innerHeight - calculateTopOffset() : window.innerHeight - 44,
                duration: 0.8,
                ease: 'power2.inOut',
            });
        }
        else {
            gsap.to(sectionElement, {
                height: window.innerHeight - 44,
                duration: 0.8,
                ease: 'power2.inOut',
            });
        }
    }



    useEffect(() => {

        const newsElement = document.querySelector('.news');
        const article = document.querySelector('article');

        isNewsOpen
        ? article?.classList.add('news-open')
        :  article?.classList.remove('news-open');

        if (!!newsElement) {
            gsap.to(newsElement, { x: isNewsOpen ? '-100%' : 0, ease: 'power3.out' });
        }

    }, [isNewsOpen]);






    return (
        <div className={`header${isMenuOpen ? ' menu-open' : isThemesOpen ? ' themes-open' : ''}`}>
            <div className='header__left'>
                <div className='header__hamburger' onClick={handleHamburgerClick}></div>
                {pathname === '/' && (
                    <>
                        <div className='header__themes'>
                            <button onClick={handleThemesButtonClick}>
                                <img src={chevron} alt='' />
                                All themes
                            </button>
                            <button className='js-switch' onClick={handleSwitchButtonClick}>
                                <img src={isNewsOpen ? globe : news} alt="Switch" />
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className='header__center'>Today's Environmental News</div>
            <div className='header__right'>
                <div className='header__date'>{date.date}</div>
                <div className='header__day'>{date.day} </div>
                <div className='header__hour'>{date.hour}<br />{date.pm ? 'PM' : 'AM'}</div>
            </div>
        </div >
    );
};



export default memo(Header);
