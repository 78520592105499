import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { triggerLatLon } from 'store/actionCreators/news';

import { INewsItem } from 'components/News/News.interfaces';



const NewsItem: FC<INewsItem> = ({ category, title, location, latlon, source_url }) => {

    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(
            triggerLatLon(latlon)
        )
    }

    return (
        <div className={`news__item news__item--${category}`}>
            <div className='news__gradient'></div>
            {window.innerWidth > 1024 ?
                <button className='link' onClick={onClick}><span className='sr-only'>go to article</span></button> :
                <a href={source_url} target='_blank' rel='noreferrer' className='link'><span className='sr-only'>go to article</span></a>
            }
            <div className='news__top'>
                <div className='lead'>{title}</div>
            </div>
            <div className='news__bottom'>
                <div className='location'>{location}</div>
            </div>
        </div>
    );
};

export default memo(NewsItem);
